import * as React from "react";

import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";
import Signup from "../../components/EmailListSignup"
import SubSenseCollectionScript from "../../components/SubSenseCollectionScript";

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout
        pageTitle="Blog | Subscribe Sense" 
        pageDescription="Tips to grow your mailing list and get value from your audience."
        footer_children={<SubSenseCollectionScript/>}
      >
          <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-8 lg:pb-28 lg:px-8">
            <div className="absolute inset-0">
              <div className="bg-white h-1/3 sm:h-2/3" />
            </div>
            <div className="relative max-w-7xl mx-auto">
              <div className="text-center">
                <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">From the blog</h2>
                <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
                  Our best tidbits on growing your mailing list and getting value from the audience you've worked hard to build.
                </p>
              </div>
                  <BlogRoll />
            </div>
          </div>
      </Layout>
    );
  }
}
